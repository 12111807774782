import React from 'react';
import { Container, Navbar } from 'react-bootstrap';

function Footer() {
    return (
        <div bg="dark" style={{backgroundColor: "#e3e3e3", marginTop: "2rem", padding: "1rem"}}>
            <br />
            <p>Harmonize 2024</p>
        </div>
        
    )
}

export default Footer;